<template>
  <div>
    <h2 v-html="$t('productionformrows_search_production_title')"></h2>
    <!-- Einlieferung von -->
    <DbmMonthPicker v-model="searchCardStore.validFrom" :dateLabel="$t('productionformrows_search_production_validFrom')" :clearable="true" />
    <!-- Einlieferung bis -->
    <DbmMonthPicker
      v-model="searchCardStore.validUntil"
      :dateLabel="$t('productionformrows_search_production_validUntil')"
      :clearable="true"
      returnType="lastOfMonth"
    />
    <!-- expanded -->
    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!-- Erfasst von -->
      <DbmDatePicker
        v-model="searchCardStore.createdFrom"
        :dateLabel="$t('productionformrows_search_production_createdFrom')"
        :clearable="true"
        v-if="!forFarmer"
      />
      <!-- Erfasst bis -->
      <DbmDatePicker
        v-model="searchCardStore.createdUntil"
        :dateLabel="$t('productionformrows_search_production_createdUntil')"
        :clearable="true"
        v-if="!forFarmer"
      />
      <!-- Art -->
      <v-select
        id="search_production_isCorrection"
        v-model="searchCardStore.isCorrection"
        :label="$t('productionformrows_search_production_isCorrection')"
        :items="isCorrectionOptions"
        item-title="text"
        item-value="value"
        clearable
        @keyup.enter="search"
      />
      <!-- Tierart -->
      <v-select
        id="search_production_animalType"
        required
        v-model="searchCardStore.animalTypeId"
        :items="animalTypesProduction"
        item-value="id"
        :item-title="this.$getLangKey()"
        :label="$t('productionformrows_search_production_animalType')"
        clearable
        @keyup.enter="search"
      />
      <!-- Summe -->
      <v-checkbox
        v-model="searchCardStore.accumulate"
        :label="$t('productionformrows_search_production_accumulate')"
        @keyup.enter="search"
        @click="onClickSum"
      ></v-checkbox>
      <!-- nur Betriebe mit MP -->
      <v-checkbox
        v-model="searchCardStore.milkQualityTesting"
        :label="$t('productionformrows_search_production_milkQualityTesting')"
        @keyup.enter="search"
        item-value="value"
        v-if="!forFarmer"
      ></v-checkbox>
      <!-- nur letzte Zeilen -->
      <v-checkbox
        v-model="searchCardStore.latestRowsOnly"
        :label="$t('productionformrows_search_production_latestRowsOnly')"
        @keyup.enter="search"
        item-value="value"
      ></v-checkbox>
      <!-- Menge von -->
      <v-text-field
        id="search_production_quantityFrom"
        v-model="searchCardStore.quantityFrom"
        :label="$t('productionformrows_search_production_quantityFrom')"
        type="number"
        @keyup.enter="search"
      />
      <!-- Menge bis -->
      <v-text-field
        id="search_production_quantityTo"
        v-model="searchCardStore.quantityTo"
        :label="$t('productionformrows_search_production_quantityTo')"
        type="number"
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'
import { useSearchProductionStore } from '@/store/SearchProductionStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SearchProduction',
  extends: baseSearchVue,
  props: {
    forFarmer: { type: Boolean, default: false, required: false }
  },
  data() {
    return {
      expanded: false,
      isCorrectionOptions: [
        { value: null, id: 0, text: this.$t('productionformrows_all') },
        {
          value: false,
          id: 1,
          text: this.$t('productionformrows_erstmeldungen')
        },
        { value: true, id: 2, text: this.$t('productionformrows_korrekturen') }
      ]
    }
  },
  computed: {
    animalTypesProduction() {
      return useAnimalTypesStore().AnimalTypesProduction
    }
  },
  components: {
    DbmDatePicker: DbmDatePicker,
    DbmMonthPicker: DbmMonthPicker
  },
  methods: {
    async onClickSum() {
      this.$emit('clicked', this.searchCardStore.accumulate)
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchProductionStore(), true)
    useAnimalTypesStore().fetchAnimalTypesProduction()
  }
})
</script>
